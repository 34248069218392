import React from 'react';
import Template from './../layouts/Template';
const NotFoundPage = () => (
	<Template title="404 page" desc="sorry you found a broken link!">
		<div className="wrapper">
			<div className="container">
				<h1>NOT FOUND</h1>
				<p>You are either early to the party or late to the party.</p>
			</div>
		</div>
	</Template>
);

export default NotFoundPage;
